<template>
    <div class="Wallet">
        <van-tabs class="tabs" v-model:active="active" @click-tab="onClickTab">
            <van-tab title="今日"></van-tab>
            <van-tab title="昨日"></van-tab>
            <van-tab title="本周"></van-tab>
            <van-tab title="本月"></van-tab>
            <van-tab title="自定义"></van-tab>
        </van-tabs>

        <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh"> -->
        <div class="data" v-show="dataList.length" id="WalletDrag">
            <div class="drag-container">
                <van-list class="list" v-model:loading="loading" :finished="finished" @load="onLoad" loading-text=" ">
                    <div class="item" v-for="item in dataList" :key="item.ID">
                        <img :src="item.USER_PIC ? require(`@/assets/profiles/${item.USER_PIC}`) : require('@/assets/img/default.png')" />

                        <span class="money">{{ item.AMT }}</span>

                        <div class="message" @click="clipboardText('.message')" :data-clipboard-text="item.ORDER_NO">
                            <p class="title">{{ item.ORDER_TYPE }}</p>

                            <p class="serial">
                                <span>订单编号：{{ item.ORDER_NO }}</span>
                                <span class="iconfont icon-a-iconpay_huaban1fuben33"></span>
                            </p>

                            <p class="date">{{ item.CREATE_DATE }}</p>
                        </div>
                    </div>
                </van-list>
            </div>
        </div>
        <NoData v-show="dataList.length == 0">
            暂无更多信息记录
        </NoData>

        <!-- <img class="toTop" v-if="dataList.length" :src="require('@/assets/img/toTop.png')" @click="toTop" /> -->
        <!-- </van-pull-refresh> -->

        <van-action-sheet v-model:show="isShow" title="自定义筛选">
            <div class="content">
                <van-cell title="开始时间" is-link :value="startTimeVal" @click="showStartTime = true" />
                <van-cell title="结束时间" is-link :value="endTimeVal" @click="showEndTime = true" />
                <van-button class="btn" type="primary" block round @click="onSubmit">立即查询</van-button>
            </div>
        </van-action-sheet>

        <van-action-sheet v-model:show="showStartTime">
            <van-datetime-picker class="action-sheet" v-model="startDate" type="date" title="请选择年月日" :min-date="minDate" :max-date="maxDate" @confirm="startConfirm" @cancel="showStartTime = false" />
        </van-action-sheet>
        <van-action-sheet v-model:show="showEndTime">
            <van-datetime-picker class="action-sheet" v-model="endDate" type="date" title="请选择年月日" :min-date="minDate" :max-date="maxDate" @confirm="endConfirm" @cancel="showEndTime = false" />
        </van-action-sheet>
    </div>
</template>

<script>
import { reactive, toRefs, nextTick, onMounted, inject } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import NoData from '@/components/NoData.vue'
import { getPayOrderList, getPayOrderExcel } from '@/api'
import { Toast } from 'vant'
import { clipboardText, arrayUnique, toTop, getTodayDate, getYesterdayDate, getCurrentWeek, getCurrentMonth, getDate } from '@/assets/js/common.js'
import BScroll from '@better-scroll/core'

export default {
    components: {
        NoData
    },
    setup() {
        const state = reactive({
            fileConfig: '',

            refreshing: false,
            loading: true,
            finished: false,
            dataList: [],

            page: 1,
            total: 0,

            active: 0,
            s_dtt: getTodayDate()[0],
            e_dtt: getTodayDate()[1],

            isShow: false,

            startTimeVal: getTodayDate()[0],
            showStartTime: false,
            startDate: new Date(),

            endTimeVal: getTodayDate()[1],
            showEndTime: false,
            endDate: new Date(),

            minDate: new Date(2020, 0, 1),
            maxDate: new Date(2025, 10, 1),

            BScroll: null
        })

        const router = useRouter()
        const store = useStore()

        const width = inject('viewWidth', 0).value
        const height = inject('viewHeight', 0).value

        state.fileConfig = store.state.fileConfig

        const Excel = () => {
            getPayOrderExcel({
                page: state.page,
                s_dtt: state.s_dtt,
                e_dtt: state.e_dtt
            })
        }

        const getData = () => {
            getPayOrderList({
                page: state.page,
                s_dtt: state.s_dtt,
                e_dtt: state.e_dtt
            }).then(res => {
                // let data = res.data
                // data.forEach(item => {
                //     // 订单状态: 0订单销售中 1订单完成 2订单撤回 3暂停打币
                //     if (item.ORDER_STATE === '0') item.order_state = '订单销售中'
                //     if (item.ORDER_STATE === '1') item.order_state = '订单完成'
                //     if (item.ORDER_STATE === '2') item.order_state = '订单撤回'
                //     if (item.ORDER_STATE === '3') item.order_state = '暂停打币'
                // })

                state.total = res.page.total
                state.dataList = state.dataList.concat(res.data)
                state.dataList = arrayUnique(state.dataList, 'ID')

                state.refreshing = false
                state.loading = false
                if (state.total <= state.page) {
                    state.finished = true
                } else {
                    state.finished = false
                }

                nextTick(() => {
                    state.BScroll.refresh()
                })
            })
        }
        getData()

        const onLoad = () => {
            state.page++
            getData()
        }

        const onRefresh = () => {
            state.refreshing = true
            state.loading = true
            state.finished = false

            state.page = 1
            getData()
        }

        const onClickTab = ({ title }) => {
            if (title === '自定义') {
                state.isShow = true
                return
            }

            if (title === '今日') [state.s_dtt, state.e_dtt] = getTodayDate()
            if (title === '昨日') [state.s_dtt, state.e_dtt] = getYesterdayDate()
            if (title === '本周') [state.s_dtt, state.e_dtt] = getCurrentWeek()
            if (title === '本月') [state.s_dtt, state.e_dtt] = getCurrentMonth()

            state.dataList = []
            state.page = 1
            getData()
        }

        const startConfirm = date => {
            state.startTimeVal = getDate(date)
            state.s_dtt = state.startTimeVal
            state.showStartTime = false
        }

        const endConfirm = date => {
            state.endTimeVal = getDate(date)
            state.e_dtt = state.endTimeVal
            state.showEndTime = false
        }

        const onSubmit = () => {
            state.isShow = false
            state.dataList = []
            state.page = 1
            getData()
        }

        const initBScroll = () => {
            state.BScroll = new BScroll('.data', {
                click: true,
                tap: true,
                probeType: 3
            })
        }

        onMounted(() => {
            document.getElementById('WalletDrag').style.height = height - 44 - 46 + 'px'
            initBScroll()
        })

        return {
            ...toRefs(state),
            router,
            clipboardText,
            onLoad,
            onRefresh,
            toTop,
            onClickTab,
            startConfirm,
            endConfirm,
            onSubmit,
            Excel
        }
    }
}
</script>

<style lang="scss" scoped>
// 下拉加载
.Wallet ::v-deep .van-pull-refresh__track {
    min-height: calc(100vh - 46px);
}

.Wallet {
    background: $background;
    position: relative;

    .data {
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;

        .drag-container {
            padding: h(12) h(15);
        }

        .item {
            position: relative;
            height: h(120);
            background: #ffffff;
            border-radius: 8px;
            margin-bottom: h(10);

            img {
                position: absolute;
                top: h(15);
                left: w(15);
                width: h(38);
                height: h(38);
                border-radius: 50%;
            }

            .money {
                position: absolute;
                top: h(24);
                right: w(15);
                font-weight: bold;
                font-size: 18px;
                color: #000000;
            }

            .message {
                display: inline-block;
                margin: h(26) 0 0 w(65);
                font-size: 12px;
                line-height: 16px;
                color: #828282;

                .title {
                    font-size: 14px;
                    color: #000000;
                }
                .serial {
                    margin: h(15) 0 h(10);

                    & span:last-child {
                        margin-left: w(20);
                        margin-right: w(10);
                        vertical-align: bottom;
                        font-size: 20px;
                        color: #3366ee;
                    }
                }
            }
        }
    }

    .toTop {
        position: fixed;
        right: h(10);
        bottom: h(10);
    }

    .content {
        padding: 10px 16px h(30);
        text-align: left;

        .btn {
            @include btn();

            margin-top: h(50);
        }
    }

    .action-sheet {
        margin: 10px;
    }
}
</style>
